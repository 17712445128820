<template>
  <a-row :gutter="25">
    <h3 class="ml-2 title-view">2. Verificación de datos</h3>
  </a-row>
  <a-row class="mt-2">
    <a-col :xxl="8" :lg="8" :md="8" :sm="24" :xs="24" style="padding: 1rem">
      <sdCards>
        <a-row>
          <a-col :xxl="24" :lg="24" :md="24" :sm="24" :xs="24" style="text-align: center;">
            <h3 class="subtitle-qr">Código QR válido</h3>
            <img
                width="200"
                height="200"
                :src="require(`@/static/img_events/SEJ_general_qr_success_response.png`)" alt="event date" />
          </a-col>
        </a-row>
      </sdCards>
    </a-col>
    <div v-if="state.response">
      <a-col :xxl="16" :lg="16" :md="16" :sm="24" :xs="24" style="padding: 1rem">
        <sdCards>
          <a-row>
            <a-col :xxl="24" :lg="24" :md="24" :sm="24" :xs="24">
              <h3 class="subtitle-qr">Información del asistente</h3>
            </a-col>
          </a-row>
          <a-row>
            <a-col :xxl="24" :lg="24" :md="24" :sm="24" :xs="24">
              <span class="title-label">Nombre del asistente:</span> <br>
              <span class="gray-title">{{ state.response.user.name + ' ' + state.response.user.last_name + '' + state.response.user.second_last_name }}</span>
            </a-col>
            <a-col :xxl="24" :lg="24" :md="24" :sm="24" :xs="24" class="mt-2">
              <span class="title-label">CURP:</span> <br>
              <span class="gray-title">{{ state.response.user.curp }}</span>
            </a-col>
            <a-col :xxl="24" :lg="24" :md="24" :sm="24" :xs="24" class="mt-2">
              <span class="title-label">Nombre del evento:</span> <br>
              <span class="gray-title">{{ state.response.event.title }}</span>
            </a-col>
            <a-col :xxl="24" :lg="24" :md="24" :sm="24" :xs="24" class="mt-2">
              <span class="title-label">Sede:</span> <br>
              <div v-for="(sede, index) in state.response.event.headquarters" :key="sede">
                <span class="gray-title">#{{ index + 1 }}</span>
                <span class="gray-title" v-if="sede.type == 'virtual'">
                  Virtual <br>
                  {{ sede.virtual_platform }}, link: {{ sede.link }}
                  <br>
                </span>
                <span class="gray-title" v-if="sede.type == 'face-to-face'">
                  Presencial <br>
                  {{ sede.name }}, {{ sede.street }}, {{ sede.suburb ? sede.suburb : sede.other_suburb }}, {{ sede.postal_code }}, {{ sede.municipality }}
                  <br>
                </span>
                <span class="gray-title" v-if="sede.type == 'both'">
                  Ambas (vitual y presencial) <br>
                  {{ sede.virtual_platform }}, link: {{ sede.link }}, {{ sede.name }}, {{ sede.street }}, {{ sede.suburb ? sede.suburb : sede.other_suburb }}, {{ sede.postal_code }}, {{ sede.municipality }}
                  <br>
                </span>
              </div>
            </a-col>
            <a-col :xxl="24" :lg="24" :md="24" :sm="24" :xs="24" class="mt-2">
              <span class="title-label">Fecha:</span> <br>
              <span class="gray-title">{{ formatDate(state.response.event.event_date) }} {{ state.response.event.event_hour }}hrs</span>
            </a-col>
          </a-row>
        </sdCards>
      </a-col>
    </div>
    <a-col :xxl="24" :lg="24" :md="24" :sm="24" :xs="24">
      <a-row :gutter="25" style="text-align: center;">
        <a-col :xxl="24" :lg="24" :md="24" :xs="24" class="mt-4 text-left">
          <button class="btn-fill-add" v-on:click="handleCaptureAnotherQr">
            <span class="title-normal"> Capturar otro QR </span>
          </button>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>
<script>

import { reactive } from "@vue/reactivity";
import { formatDate } from '@/utility/utility'
import { useRouter } from "vue-router";


const VerifyQrSuccessResponse = {
  name: 'VerifyQrSuccessResponse',
  components: {
    
  },
  props: {
    responseQR: Object
  },
  emits: ['captureAnotherQr'],
  async setup(props, {emit}) {
    const router = useRouter();

    const state = reactive({
      response: props.responseQR
    })

    const handleCaptureAnotherQr = () => {
      emit('captureAnotherQr', { captureAnotherOne: true })
    }

    const handleToReturn = () => {
      router.push({path: `/my-events/index`});
    }

    return {
      state,
      formatDate,
      handleCaptureAnotherQr,
      handleToReturn
    };
  },
};

export default VerifyQrSuccessResponse;
</script>
<style scoped>
.gray-title{
    color: #7B868C;
    font-family: 'Avenir Roman';
    font-size: 14px;
     font-weight: 100;
}
.title-label {
  color: #404040;
  font-family: 'Avenir Heavy';
  font-size: 14px;
  font-weight: 600;
}
.title-view{
  color: #404040;
  font-family: 'Nutmeg Bold';
  font-size: 18px;
}
.subtitle-qr{
  color: #404040;
  font-family: 'Avenir Heavy';
  font-size: 18px;
  font-weight: 500;
}
.btn-outlined-exit{
  border: 1px solid #636668;
  color: #636668;
  padding: 5px 15px 5px 15px;
  background: none;
  border-radius: 24px;
  margin-left: 2rem;
  cursor: pointer;
}
.btn-fill-add{
  border: 1px solid #3FA7E1;
  color: #ffffff;
  padding: 5px 15px 5px 15px;
  background: #3FA7E1;
  border-radius: 24px;
  cursor: pointer;
}
</style>
